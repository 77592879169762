<template>
  <div class="title">
    <h3>{{ title }}</h3>
    <a @click="clickMore" v-if="!noMore">See more</a>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["title", "noMore"],
  methods: {
    clickMore() {
      this.$emit("clickMore");
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  h3 {
    font-size: 18px;
    font-family: Poppins-Bold,Poppins;
    font-weight: 700;
    color: #121a37;
    line-height: 27px;
    text-transform:capitalize;
  }
  a {
    font-size: 12px;
    font-family: Poppins-Medium,Poppins;
    font-weight: 500;
    color: #8500ff;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
