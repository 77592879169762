import axios from "axios";
import { decodeCdnResult } from "@/api/utils";

export const get_chapter_result = async (url, secret_key) => {
  try {
    const result = await axios.get(url);
    let result_str = decodeCdnResult(result.data, secret_key.substring(0, 16), secret_key.substring(16, 32));
    result_str = result_str.replace(/§/g, "<br/>")
    return result_str;
  } catch (e) {
    console.log("e", e);
  }
}
