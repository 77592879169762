import request from "../base/base.js";
import { register, refresh, reset_password, email_captcha} from "../types/login_const";
import config from "@/config";
import {getUUid} from "@/api/utils";
import web_local_storage from "@/utils/localstorage/web_local_storage";
import {ACCESS_TOKEN} from "@/utils/localstorage/web_local_keys";

export const register_account = async (login_id, extra_param, type, basic_param) => {
  try {
    const device_id = await getUUid();
    const params = {
      login_id,
      device_id,
      platform: config.PLATFORM,
      type,
      extra_param,
    };
    basic_param && (params.basic_param = basic_param);
    const res = await request.post(register, params);
    const result = JSON.parse(res);
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const refreshToken = async () => {
  try {
    const res = await request.post(refresh, null, {
      refresh: true
    });
    console.log(" refreshToken", JSON.parse(res).access_token);
    web_local_storage.set(ACCESS_TOKEN, JSON.parse(res).access_token)
  } catch (e) {
    // return Promise.reject(e);
  }
}

export const email_confirm = async (email) => {
  try {
    const res = await request.post(email_captcha, {
      email,
      platform: config.PLATFORM
    })
    const result = JSON.parse(res);
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const reset_user_password = async (email, new_password, captcha) => {
  try {
    const res = await request.post(reset_password, {
      email,
      platform: config.PLATFORM,
      new_password,
      captcha
    })
    const result = JSON.parse(res);
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}
