import request from "@/api/base/base";
import {user_info} from "@/api/types/user_const";

export const get_user_info = async () => {
  try {
    const res = await request.get(user_info);
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}
