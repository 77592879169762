<template>
  <div class="read_btn">
    <img src="@/assets/img/recommend.png"/>
  </div>
</template>

<script>
export default {
name: "recommendApp"
}
</script>

<style scoped lang="scss">
  .read_btn {
    padding: 12px 30px 0;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
</style>
