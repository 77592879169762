<template>
  <div class="rate-container">
    <span class="rate-text">
      {{rate}}
    </span>
    <van-rate
        :icon="icon_src"
        :void-icon="icon_src_default"
        :size="12"
        allow-half
        v-model="rete_num"
        color="#fe9900"
        readonly></van-rate>
  </div>
</template>

<script>
export default {
  name: "RateItem",
  props: ["rate"],
  computed: {
    rete_num() {
      return this.rate ? this.rate / 2 : 0;
    }
  },
  data() {
    return {
      icon_src: require("@/assets/img/Star_Copy_2-3x.png"),
      icon_src_default: require("@/assets/img/Star_Copy_3-3x.png")
    }
  }
}
</script>

<style scoped lang="scss">
.rate-container {
  display: flex;
  align-items: center;
  .rate-text {
    font-family: Poppins-Medium,Poppins;
    color: #fe9900;
    line-height: 17px;
    font-size: 12px;
    margin-right: 4px;
    height: 17px;
  }
}
</style>
<style lang="scss">
.rate-container {
  .van-rate__icon {
    font-size: 14px!important;
  }
  .van-rate__item:not(:last-child) {
    padding-right: 0;
  }
}
</style>
