<template>
  <div>
    <img src="@/assets/img/back.png" class="icon_back" @click="goBack"/>
    <div>待开发</div>
  </div>
</template>

<script>
export default {
name: "index",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>
