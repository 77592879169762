<template>
  <van-image class="book-van-img" lazy-load :src="src">
    <template v-slot:loading>
      <van-loading type="spinner" size="20" />
    </template>
  </van-image>
</template>

<script>
export default {
  name: "BookImg",
  props: ["src"],
}
</script>

<style lang="scss">
.book-van-img {
  &.van-image {
    width: 100%;
    height: 100%;
    background: #f6f7fc;
    .van-image__img {
      border-radius: 4px;
    }
    .van-image__loading {
      background: #f6f7fc;
    }
    .van-image__error {
      background: #f6f7fc;
    }
  }
}
</style>
