import request from "@/api/base/base";
import {setting} from "@/api/types/setting_const";

export const fetch_setting = async () => {
  try {
    const res = await request.get(setting, {
      params: {
        platform: "iOS_Novel_Test"
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}
