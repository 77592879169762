import { createRouter, createWebHistory } from 'vue-router'
// import RecordHistory from "@/utils/record";
//导入组件
import Home from '@/views/Home.vue'
import Store from '@/views/store/index';
import Browse from '@/views/browse/index';
import Detail from '@/views/detail/index';
import Read from '@/views/read/index';
import Login from '@/views/login/index';
import Search from '@/views/search/index';
import Library from '@/views/library/index';
import UserCenter from '@/views/userCenter/index';
import BookMore from '@/views/bookmore/index';
import CoinStore from '@/views/coinStore/index';
import Unlocked from '@/views/unlocked/index';
import Record from '@/views/record/index';
import Wallet from '@/views/wallet/index';
import Catalog from '@/views/catalog/index';
import TransactionList from '@/views/transactionList/index';
import History from '@/views/history/index';
import OrderDetail from '@/views/orderDetail/index';
import MyStories from '@/views/MyStories/index';
import Create from '@/views/create/CreateBook.vue';
import NotFindPage from '@/views/404/index';
import Chapter from "@/views/create/Chapter.vue";
import CreateChapter from "@/views/create/CreateChapter.vue";
import Publish from "@/views/create/Publish.vue";
import Trash from "@/views/create/Trash.vue";
import Test from "@/views/test";
import {editThemeColor} from "@/utils/themeColor";

//路由规则
const routes = [
  {
    path: '/home',
    component: Home,
    name: "Home",
    children: [
      {
        path: "store",
        name: "Store",
        component: Store,
        meta: {
          themeColor: "#8500ff"
        }
      }, {
        path: "library",
        name: "Library",
        component: Library,
        meta: {
          themeColor: "#8500ff"
        }
      }
    ],
  },
  {
    path: "/browse",
    name: "Browse",
    component: Browse
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
    meta: {
      themeColor: "#191924"
    }
  },
  {
    path: "/read/:id",
    name: "Read",
    component: Read
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      themeColor: "#3f217f"
    }
  }, {
    path: "/search",
    name: "Search",
    component: Search
  }, {
    path: "/user_center",
    name: "UserCenter",
    component: UserCenter,
    meta: {
      themeColor: "#5e36b4"
    }
  }, {
    path: "/book_more",
    name: "BookMore",
    component: BookMore
  }, {
    path: "/unlocked",
    name: "Unlocked",
    component: Unlocked
  }, {
    path: "/coin_store",
    name: "CoinStore",
    component: CoinStore
  }, {
    path: "/record",
    name: "Record",
    component: Record
  }, {
    path: "/wallet",
    name: "Wallet",
    component: Wallet
  }, {
    path: "/catalog",
    name: "Catalog",
    component: Catalog
  }, {
    path: "/history",
    name: "History",
    component: History
  }, {
    path: "/transaction_list",
    name: "TransactionList",
    component: TransactionList
  }, {
    path: "/order_detail/:result",
    name: "OrderDetail",
    component: OrderDetail
  }, {
    path: "/stories",
    name: "MyStories",
    component: MyStories
  }, {
    path: "/create",
    name: "Create",
    component: Create
  }, {
    path: "/chapter",
    name: "Chapter",
    component: Chapter
  }, {
    path: "/create-chapter",
    name: "CreateChapter",
    component: CreateChapter
  }, {
    path: "/create_set",
    name: "Publish",
    component: Publish
  }, {
    path: "/trash",
    name: "trash",
    component: Trash
  }, {
    path: "/Test",
    name: "Test",
    component: Test
  }, {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFindPage
  }
]
//路由创建
const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history"
})

router.beforeEach((to, from) => {
  // ...
  console.log("to, from", to, from);
  editThemeColor(to && to.meta && to.meta.themeColor);
  // 返回 false 以取消导航
  return true;
})

// export function resetRouter () {
//   const newRouter = createRouter({
//     history: createWebHistory(),
//     routes,
//     mode: "history"
//   })
//   router.matcher = newRouter.matcher // the relevant part
// }
export default router
