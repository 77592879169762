import request from "../base/base.js";
import {
  tab_list,
  tab_detail,
  section_view_all,
  detail,
  chapter_detail,
  fuzzy_search,
  recommend, search,
  genres_list,
  genres_view_all,
  operation,
  user_collect,
  report_read_history,
  viewed,
  unlock_all,
  unlock,
  history,
  unlock_history,
  tags,
  chapter_list
} from "../types/book_const";

export const books_tab_list = async () => {
  try {
    const res = await request.get(tab_list);
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const book_section = async () => {
  try {
    const res = await request.get(tab_detail, {
      params: {
        tab_ids: "1, 2"
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const all_books = async (section_id, tab_id, offset = 0, limit = 20) => {
  try {
    const res = await request.get(section_view_all, {
      params: {
        tab_id,
        section_id,
        offset,
        limit
      }
    });
    console.log("all_books", JSON.parse(res));
    return JSON.parse(res);
  } catch (e) {
    console.log("e", e);
    return Promise.reject(e);
  }
}

export const novel_detail = async (novel_id) => {
  try {
    const res = await request.get(detail, {
      params: {
        novel_id
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_chapter_list = async (novel_id) => {
  try {
    const res = await request.get(chapter_list, {
      params: {
        novel_id,
        limit: 50000,
        offset: 0
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_chapter_detail = async (novel_id, chapter_id) => {
  try {
    const res = await request.get(chapter_detail, {
      params: {
        novel_id,
        chapter_id
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_fuzzy_search = async (search_term, offset = 1, limit = 10) => {
  try {
    const res = await request.get(fuzzy_search, {
      params: {
        search_term,
        offset,
        limit
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

/*
* novel_tags是根据小说的tag来推荐
  user_tags是根据用户一开始选择的tag来推荐
  hot就是热门
  hot_search是热搜
  type: novel支持novel_tags/user_tags/hot/hot_search
  type: tag暂时只支持hot
* */
export const novel_recommend = async (type, basis, value) => {
  try {
    const params = {
      type,
      basis
    };
    value && (params.value = value);
    const res = await request.get(recommend, {
      params
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_search = async (search_term, offset = 0, limit = 10) => {
  try {
    const res = await request.get(search, {
      params: {
        search_term,
        offset,
        limit
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_genres_list = async () => {
  try {
    const res = await request.get(genres_list);
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const load_genres_novels = async (
  genre_id,
  offset = 0,
  sortBy = "like_number",
  contentWordsMin = 10,
  contentWordsMax = 10000000,
  limit = 20
  ) => {
  try {
    console.log('load_genres_novels', {
      params: {
        genre_id,
        sortBy,
        offset,
        limit,
        contentWordsMin: contentWordsMin ? contentWordsMin : 10,
        contentWordsMax : contentWordsMax ? contentWordsMax : 10000000
      }
    })
    const res = await request.get(genres_view_all, {
      params: {
        genre_id,
        sortBy,
        offset,
        limit,
        contentWordsMin: contentWordsMin ? contentWordsMin : 10,
        contentWordsMax : contentWordsMax ? contentWordsMax : 10000000
      }
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_operation = async (novel_ids, type = "collect") => {
  try {
    const res = await request.post(operation, {
      target_ids: novel_ids.toString(),
      operation: type,
      target_type: "novel"
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const user_collect_novels = async (offset) => {
  try {
    const res = await request.get(user_collect, {
      params: {
        offset
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const report_novel_read_history = async (novel_id, chapter_id, percent, read_time) => {
  try {
    const res = await request.post(report_read_history, {
      novel_id,
      chapter_id,
      percent,
      read_time
    });
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const novel_history = async (page_num) => {
  try {
    const res = await request.get(viewed, {
      params: {
        offset: page_num
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const download_novel = async (novel_id, unlock_type = "coins") => {
  try {
    const res = await request.post(unlock_all, {
      novel_id: parseInt(novel_id),
      unlock_type
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const unlock_chapter = async (novel_id, current_order, chapter_num, unlock_type = "coins") => {
  try {
    const res = await request.post(unlock, {
      type: {
        priority: unlock_type
      },
      target: {
        novel_id: parseInt(novel_id),
        current_chapter_order: current_order,
        batch_num: chapter_num
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const history_list = async (type="iap", offset) => {
  try {
    const res = await request.get(history, {
      params: {
        type,
        offset
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const unlock_history_list = async (offset) => {
  try {
    const res = await request.get(unlock_history, {
      params: {
        offset
      }
    });
    return JSON.parse(res)
  } catch (e) {
    return Promise.reject(e);
  }
}

export const tag_list = async () => {
  try {
    const res = await request.get(tags, {
      params: {
        offset: 0,
        limit: 250
      }
    })
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}
