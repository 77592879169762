export const tab_list = "/web/v1/novel/tab_list";
export const tab_detail = "/web/v1/novel/tab_detail";
export const section_view_all = "/web/v1/novel/section_view_all";
export const genres_list = "/web/v1/novel/genre_list";
export const recommend = "/web/v1/novel/recommend";
export const genres_view_all = "/web/v1/novel/genre_view_all";
export const fuzzy_search = "/web/v1/novel/fuzzy_search";
export const search = "/web/v1/novel/search";
export const detail = "/web/v1/novel/novel_detail";
export const chapter_list = "/web/v1/novel/chapter_list";
export const operation = "/web/v1/novel/operation";
export const comment = "/web/v1/novel/comment";
export const chapter_detail = "/web/v1/novel/chapter_detail";
export const unlock = "/web/v1/novel/unlock";
export const user_collect = "/web/v1/novel/collect_list";
export const report_read_history = "/web/v1/novel/report_read_history";
export const viewed = "/web/v1/novel/viewed_history";
export const unlock_all = "/web/v1/novel/unlock_all";
export const history = "/web/v1/novel/trade_history";
export const tags = "/web/v1/novel/tag_list";
export const unlock_history = "/web/v1/novel/unlock_history";
