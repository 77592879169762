<template>
  <div>
    <header-back
        :back-fixed="true"
        title="Chapters"
        :full-title="true"
        :right-custom="true"
    >
      <template v-slot:right>
        <div class="navbar_right">
          <span class="del_all disabaled">Delete All</span>
        </div>
      </template>
    </header-back>
    <div class="no_data">
      <img src="@/assets/img/create/null_trash.png" />
      <p>There is nothing here!</p>
      <div>Back</div>
    </div>
  </div>
</template>

<script>
import {Options, Vue} from "vue-property-decorator";
import HeaderBack from "@/components/HeaderBack.vue";
@Options({
  components: {
    HeaderBack
  }
})
export default class Trash extends Vue {
}
</script>

<style scoped lang="scss">
  .navbar_right {
    padding-right: 16px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    .del_all {
      font-weight: 500;
      color: #f74a4a;
      white-space: normal;
      font-family: Poppins-Medium;
      font-size: 13px;
      &.disabled {
        color: rgba(238,51,51,.4);
      }
    }
  }
  .no_data {
    padding-top: 140px;
    text-align: center;
    img {
      width: 149px;
      height: 149px;
      display: block;
      margin: 0 auto;
    }
    p {
      font-weight: 400;
      color: #686d7d;
      font-size: 15px;
      margin-top: 24px;
    }
    div {
      width: 270px;
      height: 44px;
      line-height: 44px;
      border-radius: 2px;
      border: 1px solid #8500ff;
      font-size: 16px;
      font-family: Poppins-Bold;
      font-weight: 700;
      text-align: center;
      margin: 16px auto;
      color: #8500ff;
    }
  }
</style>
