<template>
  <div class="dvs-sty">
    <img src="@/assets/img/diversion.png" />
  </div>
</template>

<script>
export default {
name: "SplitBanner"
}
</script>

<style scoped lang="scss">
.dvs-sty {
  padding: 0 18px;
  cursor: pointer;
  img {
    width: 100%;
  }
}
</style>
