import { decodeResult } from "@/api/utils"
import {refreshToken} from "@/api/service/login";
import instance from "../base/base";
import {isObject} from "@/utils/utils";
import {Router} from "@/main";
import {Toast} from "vant";

export const decrypt_interceptor = async (response) => {
  try {
    const result = isObject(response.data) ? response.data : JSON.parse(response.data);
    console.log("result===",response, result);
    // 如果token失效
    if (result && result.meta && result.meta.code === 20007) {
      // 需要response的解密，并且返回的数据不是对象形式
      await refreshToken();
      const backoff = new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        },  1);
      });

      // Return the promise in which recalls axios to retry the request
      return backoff.then(() => {
        return instance(response.config);
      });
    } else if(result && result.meta && result.meta.code === 20008 || result.meta.code === 20002) {
      setTimeout(() => {
        Router.push("/login");
        Toast(result.meta.message);
      }, 2000);
      return Promise.reject(result.meta.message);
    } else if (result && result.meta && result.meta.code === 200) {
      return decodeResult(result.data);
    } else {
      return Promise.reject(result.meta);
    }
  } catch (error) {
    console.log("error==", error, response);
    return Promise.reject(error);
  }
}
