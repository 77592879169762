import { interceptUrlParamsToQuery, transformParamsToString } from "@/utils/utils.ts";
import { getEncryptData } from "@/api/utils";
import web_local_storage from "@/utils/localstorage/web_local_storage";
import configs from "../../config/index.js";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "@/utils/localstorage/web_local_keys";
export const DEFAULT_PARAMS = {
  url: configs.BASE_URL,
  timeout: 15000,
  method: "POST"
};

export const encrypt_interceptor = async (config) => {
  const headers = {
    "content-type": "application/json"
  };
  // 如果是重试，直接返回config,但是需要使用最新的ACCESS_TOKEN
  if (config.url === DEFAULT_PARAMS.url) {
    return {
      ...config,
      headers: {
        ...headers,
        Authorization: web_local_storage.get(ACCESS_TOKEN)
      }
    };
  }
  const refresh = config.refresh;
  // const default_header = config.header ? config.header : {};

  const dataBody = {
    method: config.method.toUpperCase(),
    path: config.url,
  };
  if (dataBody.method === "GET") {
    dataBody.query = transformParamsToString(config.params);
    const { result_url, result_query } = interceptUrlParamsToQuery(config.url);
    result_url && (dataBody.path = result_url);
    result_query && (dataBody.query += "&" + result_query);
    config.params &&
    (config.url = `${config.url}?${transformParamsToString(config.params)}`);
  } else {
    dataBody.body = config.data
  }
  const encodeURL = encodeURI(config.url);
  config.url = encodeURL;
  console.log("data",dataBody);
  // 如果是refresh， 需要使用REFRESH_TOKEN，否者使用ACCESS_TOKEN
  const authorization = refresh ? web_local_storage.get(REFRESH_TOKEN) : web_local_storage.get(ACCESS_TOKEN);
  authorization && (headers.Authorization = authorization);
  return {
    ...DEFAULT_PARAMS,
    data: JSON.stringify(getEncryptData(dataBody)),
    headers
  };
}
